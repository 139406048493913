<template>
    <div :class="[flag? '': 'content-box']">
        <div :class="[flag? '': 'container']">
            <div class=""  :class="[flag? 'contentM': 'content']">
                <div class="content_row"><h4 class="global_head_title">任务详情</h4></div>
                <div class="content_row flex">
                    <label class="info" for=""><span>任务类型：</span></label><div>{{this.type}}</div>
                </div>
                <div class="content_row flex" style="line-height: 5rem;">
                    <label class="info" for=""><span>任务描述：</span></label><div>{{this.desc}}</div>
                </div>
                <div class="content_row flex">
                    <label class="info" for=""><span>开始时间：</span></label><div>{{this.start}}</div>
                </div>
                <div class="content_row flex border_no">
                    <label class="info" for=""><span>结束时间：</span></label><div>{{this.end}}</div>
                </div>
                <div class="flex" style="align-items: normal;"  :class="[flag? 'progres': 'content_row']">
                    <label class="info" for="">任务进度：</label>
                    <a-table :columns="taskColumns" :data-source="taskList" class="table" style="width:80%;" :bordered="true" :pagination="false" v-if="Boolean(taskId) == false">
                        <template slot="Action" slot-scope="text,record">
                            <a class="action" href="javascript:;" @click="finishTask(record.id)" v-if="record.status != '完成'">完成</a>
                        </template>
                    </a-table>
                </div>
                <a-list :data-source="taskList" v-if="Boolean(taskId) == true">
                    <a-list-item slot="renderItem" slot-scope="item" >
                        <div style="width: 95%;margin: 0 auto">
                            <div class="content_rowM flex">
                                <div>客户名称:{{item.corp_name}}</div>
                                <div>任务状态:{{item.status}}</div>
                            </div>
                            <div class="content_rowM flex">
                                <div>跟进人:{{item.staff_name}}</div>
                                <div>完成时间:{{item.endtime}}</div>
                            </div>
                            <div class="f_left" v-if="item.status != '完成'">
                                <a class="action" href="javascript:;" @click="finishTask(item.id)">完成</a>
                            </div>
                        </div>
                    </a-list-item>
                </a-list>
            </div>
        </div>
    </div>
</template>
<script>
// import $ from "jquery";
import {requestXml,getDateTime,isMobile} from '../../../assets/js/request';
export default {
    props: {
        addFlag: {
            type: Boolean,
            default: false
        },
        taskId: {
            type: String
        }
    },
    data() {
        return {
            flag: true,

            type: 0,
            desc: "",
            start: "",
            end: "",

            // 任务id
            id: "",
            taskColumns:[
                { title: '客户名称', dataIndex: 'corp_name'},
                { title: '任务状态', dataIndex: 'status'},
                { title: '跟进人', dataIndex: 'staff_name'},
                { title: '完成时间', dataIndex: 'endtime' },
                {
                    title: '操作',
                    width: 100,
                    scopedSlots: { customRender: 'Action' },
                }
            ],
            taskList:[],
            taskType: ["打电话","发短信","发微信","发邮件","分享文章","分享表单","分享文件","分享商品","客户拜访","其他"],
        };
    },
    mounted: function(){
        this.id = this.$route.query.id;
        if(this.id){
            this.getTaskDetail();
        }
        if(isMobile()){
            this.flag = true;
        }else{
            this.flag = false;
        }
    },
    watch:{
        taskId: function(newVal){ 
            this.id = newVal;
        },
    },
    methods: {
        // 获取任务详情
        getTaskDetail() {
            requestXml("/scrm/Task/getDetails","GET",(res) => {
                console.log(res)
                this.type = this.taskType[Number(res.type-1)]
                this.desc = res.task_content;
                this.start = getDateTime(res.start);
                this.end = getDateTime(res.end);
                for(let i=0;i<res.list.length;i++){
                    res.list[i].endtime = getDateTime(res.list[i].endtime);

                    switch(res.list[i].status){
                        case 0:
                            res.list[i].status = "取消"
                            break;
                        case 1:
                            res.list[i].status = "新建"
                            break;
                        case 2:
                            res.list[i].status = "跟进中"
                            break;
                        default:
                            res.list[i].status = "完成"
                    }
                }
                this.taskList = res.list;
            },{id: this.id})
        },
        // 完成
        finishTask(id){
            requestXml("/scrm/Task/setDone","POST",(res) => {
                console.log(res)
                this.getTaskDetail();
            },{"id": id})
        },
    }
};
</script>
<style scoped>
    .action{
        display: inline-block;
        padding: 5px 10px;
        margin: 0 5px;
        background: #DCEEFF;
        border: 1px solid #36A3FF;
        color: #36A3FF;
        border-radius: 5px;
    }
    .del{
        background: #FFDCDC;
        border-color: #FF4C4C;
        color: #FF4C4C;
    }
    .ant-list{
        padding: 0 3.5rem;
    }
    .ant-list .content_row,.ant-list .content_rowM{
        justify-content: space-between;
    }
    .ant-list .ant-list-item{
        background: #fff;
        border: 0;
        border-radius: 10px;
        margin: 2rem 0;
    }
    .contentM .content_row{
        align-items: center;
        /* height: 7rem; */
        line-height: 7rem;
        padding: 0 2.5rem;
        /* font-weight: 700; */
        background: #fff;
        color: #000;
        border-bottom: 1px solid #eee;
    }
    .contentM .content_row .info{
        display: inline-block;
        width: 17rem;
        text-align: right;
        padding-right: 2rem;
        font-weight: bold;
    }
    .contentM .content_rowM{
        padding: 1rem 0;
        color: #202020;
    }
    .progres{
        margin: 2.5rem 3.5rem;
    }

    @media screen and (min-width: 750px){
        .content .content_row{
            margin: 15px 0;
            font-size: 16px;
        }
        .content .content_row .info{
            width: 90px;
            padding-right: 10px;
        }
         .content .content_row .info span{
            width: 90px;
         }
    }
</style>
