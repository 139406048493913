<template>
    <div class="box page">
        <detail :taskId="taskId"></detail>
    </div>
    
</template>

<script>
import detail from '../../scrm_pc/task/taskDetail'  
export default {
    components: {
        'detail': detail  //将别名demo 变成 组件 Demo
    },
    data() {
        return {
            taskId: ""
        }
    },
    mounted: function(){
        console.log(this.$route.query.id);
        this.taskId = this.$route.query.id;
    },
}

</script>
<style>
    
</style>
